import React from 'react';
import { DescriptionFieldProps } from "@rjsf/utils";

const DescriptionFieldTemplate = (props: DescriptionFieldProps) => {
    const { description, id } = props;
    if (id == 'undefined__description') {
        return null
    }
    //root description field
    if (props.uiSchema) {
        return (
            <div style={{color:'#737373', textAlign: 'left', marginBottom: '30px' }}  >
                {description}
            </div>
        )
    }
    return (
        <header id={id}>
            {description}
        </header>
    )
}

export default DescriptionFieldTemplate;