import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react';


export interface ServiceField {
  type: string | number;
  name: string;
  description: string;
  required?: boolean;
  schema?: {
    type: string;
    [key: string]: any;
  };
}


export interface ServiceCreationState {
  basicInfo: {
    name: string;
    description: string;
    isPrivate: boolean;
  };
  selectedProperties: ServiceField[];
  jsonSchema: string;
  currentStep: number;
  serviceId?: number;
  readme?: string;
}

interface ServiceCreationContextType {
  state: ServiceCreationState;
  setBasicInfo: (info: ServiceCreationState['basicInfo']) => void;
  addProperty: (property: ServiceField) => void;
  removeProperty: (propertyName: string) => void;
  setJsonSchema: (schema: string) => void;
  resetState: () => void;
  // New navigation methods
  nextStep: () => void;
  prevStep: () => void;
  setStep: (step: number) => void;
  setServiceId: (id: number) => void;
  setReadme: (content: string) => void;
}

const initialState: ServiceCreationState = {
  basicInfo: {
    name: '',
    description: '',
    isPrivate: false,
  },
  selectedProperties: [],
  jsonSchema: '',
  currentStep: 0,
};

const ServiceCreationContext = createContext<ServiceCreationContextType | undefined>(undefined);

export function ServiceCreationProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<ServiceCreationState>(initialState);

  const setBasicInfo = (info: ServiceCreationState['basicInfo']) => {
    setState(prev => ({ ...prev, basicInfo: info }));
  };

  const addProperty = (property: ServiceField) => {
    setState(prev => ({
      ...prev,
      selectedProperties: [...prev.selectedProperties, property]
    }));
  };

  const removeProperty = (propertyName: string) => {
    setState(prev => ({
      ...prev,
      selectedProperties: prev.selectedProperties.filter(p => p.name !== propertyName)
    }));
  };

  const setJsonSchema = (schema: string) => {
    setState(prev => ({ ...prev, jsonSchema: schema }));
  };

  const resetState = () => {
    setState(initialState);
  };

  const setServiceId = (id: number) => {
    setState(prev => ({ ...prev, serviceId: id }));
  };

  const setReadme = (content: string) => {
    setState(prev => ({ ...prev, readme: content }));
  };

  const nextStep = () => {
    setState(prev => ({
      ...prev,
      currentStep: Math.min(prev.currentStep + 1, 3)
    }));
  };

  const prevStep = () => {
    setState(prev => ({
      ...prev,
      currentStep: Math.max(prev.currentStep - 1, 0)
    }));
  };

  const setStep = (step: number) => {
    setState(prev => ({
      ...prev,
      currentStep: Math.min(Math.max(step, 0), 3)
    }));
  };

  const value = useMemo(() => ({
    state,
    setBasicInfo,
    addProperty,
    removeProperty,
    setJsonSchema,
    resetState,
    nextStep,
    prevStep,
    setStep,
    setServiceId,
    setReadme,
  }), [state]); // Only recreate when state changes

  return (
    <ServiceCreationContext.Provider value={value}>
      {children}
    </ServiceCreationContext.Provider>
  );
}

export const useServiceCreation = () => {
  const context = useContext(ServiceCreationContext);
  if (context === undefined) {
    throw new Error('useServiceCreation must be used within a ServiceCreationProvider');
  }
  return context;
};