import moment from "moment";

export const getToolTipLabelForDuration = (yLabel: string, value: number) => {
    // Human-readable tooltip for average completion time
    let seconds = 0;
    if (yLabel==="Seconds")
        seconds = value;
    else if (yLabel==="Minutes")
        seconds = value * 60;
    else if (yLabel==="Hours")
        seconds = value * (60 * 60);
    else if (yLabel==="Days")
        seconds = value * (60 * 60 * 24);

    const m = moment.duration(seconds, 'seconds');
    return m.humanize()
}