import ServiceList from './ServiceList';
import ServiceCreate from './ServiceCreator/ServiceCreate';
import React from 'react';
import { isVisibleCreateServiceButton } from './utils';

const ConditionalServiceCreate = (props: any) => {
  return isVisibleCreateServiceButton() ? <ServiceCreate {...props} /> : null;
};

export default {
  list: ServiceList,
  create: ConditionalServiceCreate
}
