import { Collapse, Space, Typography} from 'antd';
import React from 'react';
const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const ValidationErrors = ({ errors }: { errors: any }) => {
    const handleMetadataErrors = (metadata: any, prefix: string) => 
        Object.entries(metadata).map(([field, error]) => `${prefix} - ${field} - ${error}`);

    const handleServiceItemErrors = (serviceItemData: any, appName: string, serviceItemName: string) =>
        Object.entries(serviceItemData).map(([field, error]) => 
            `${appName} - ${serviceItemName} - ${field} - ${error}`);

    const handleServiceErrors = (services: any, appName: string) => {
        const errors: string[] = [];
        
        Object.entries(services).forEach(([serviceName, serviceData]: [string, any]) => {
            if (serviceName === "metadata") {
                errors.push(...handleMetadataErrors(serviceData, `${appName} - metadata`));
            } else if (serviceName === "general") {
                errors.push(`${appName} - ${serviceData}`);
            } else if ("state" in serviceData) {
                errors.push(`${appName} - ${serviceName} - ${serviceData.state}`);
            } else {
                Object.entries(serviceData).forEach(([serviceItemName, serviceItemData]) => {
                    errors.push(...handleServiceItemErrors(serviceItemData, appName, serviceItemName));
                });
            }
        });
        
        return errors;
    };

    const extractErrors = (jsonResponse: any) => {
        const errors: string[] = [];

        Object.entries(jsonResponse).forEach(([teamName, teamData]: [string, any]) => {
            if (["metadata", "general"].includes(teamName)) {
                //metadata and general errors returned as strings not actually teamdata
                errors.push(teamData);
                return;
            }

            Object.entries(teamData).forEach(([appName, appData]: [string, any]) => {
                if (appName === "metadata") {
                    errors.push(...handleMetadataErrors(appData, "Team Metadata"));
                } else if (appName === "general") {
                    errors.push(`Team - ${appData}`);
                } else {
                    errors.push(...handleServiceErrors(appData, appName));
                }
            });
        });

        return errors;
    };

    const generateList = (errors: any) => {
        const extractedErrors = extractErrors(errors)
        return (
            <Paragraph>
                <ul style={{textAlign:"left"}}>
                    {extractedErrors.map((err, index) => <li key={index}>{err}</li>)}
                </ul>
            </Paragraph>
        )
    }
    return (
        <Space direction="vertical" style={{margin: "5px"}}>
            <Collapse bordered={false} style={{backgroundColor: "#fff2f0", borderColor:"#ffccc7", width: "500px", marginBottom:"5px"}}>
            <Panel header={<><Text>Validation Failed</Text></>} key="1">
                    {generateList(errors)}
                </Panel>
            </Collapse>
        </Space>
    );
};

export default ValidationErrors;
