import { Card, Steps, Typography } from "antd";
import React from "react";
import { BasicInfoForm } from "./steps/BasicInfoForm";
import { PropertySelection } from "./steps/PropertySelection";
import { SchemaEditor } from "./steps/SchemaEditor";
import { ReadmeEditor } from "./steps/ReadmeEditor";
import { ServiceCreationProvider, useServiceCreation } from "./contexts/ServiceCreationContext";

const { Title } = Typography;

const steps = [
  {
    title: 'Basic Information',
    content: <BasicInfoForm />,
  },
  {
    title: 'Schema Definition',
    content: <PropertySelection />,
  },
  {
    title: 'Schema Editor',
    content: <SchemaEditor />,
  },
  {
    title: 'Documentation',
    content: <ReadmeEditor />,
  },
];

const ServiceCreateContent = () => {
  const { state, setStep } = useServiceCreation();

  const onChange = (current: number) => {
    // Only allow navigating to steps we've already reached
    if (current <= state.currentStep) {
      setStep(current);
    }
  };

  return (
    <Card style={{ padding: '24px' }} className="header-solid h-full ant-card pt-0">
      <Title level={3}>Create Service</Title>
      
      <Steps
        current={state.currentStep}
        onChange={onChange}
        style={{ marginBottom: '24px' }}
      >
        {steps.map(item => (
          <Steps.Step 
            key={item.title} 
            title={item.title}
            disabled={steps.indexOf(item) > state.currentStep}
          />
        ))}
      </Steps>

      <div>{steps[state.currentStep].content}</div>
    </Card>
  );
};

const ServiceCreate = () => {
  return (
    <ServiceCreationProvider>
      <ServiceCreateContent />
    </ServiceCreationProvider>
  );
};

export default ServiceCreate;
