import { LogoutOutlined, MenuOutlined, QuestionCircleOutlined, TeamOutlined } from '@ant-design/icons';
import { faUserCircle, faUserFriends, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Dropdown,
  Layout as AntLayout,
  Menu,
  Tooltip,
  Row,
  Typography,
  Modal,
  Space,
  Divider,
  Avatar,
  Badge
} from "antd";
import React, { useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { Link } from "react-router-dom";
import Licenses from '../Licenses';
import WelcomeScreen from "../Welcome";
import useGetGeneralSettings from "../../providers/useGetGeneralSettings";
import Breadcrumbs from './Breadcrumbs';
import SearchBar from "../Common/SearchBar";
import { getActiveMembership } from "@src/components/Common/utils";
import ActivateMembership from '../Teams/SubComponents/ActivateMembership';
const { Header } = AntLayout;
const { Text } = Typography;


const AppHeader = ({ logout, pathName, toggleSidebar }: { logout: any, pathName: any, toggleSidebar: any }) => {
  const generalSettings = useGetGeneralSettings();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const feedback = generalSettings.find(setting => setting.name == 'Feedback')
  const { identity } = useGetIdentity();
  const activeMembership = getActiveMembership()
  const activeTeamName = activeMembership?.team?.name

  const userMenu = (
    <Menu style={{ width: "250px" }}>
      <Menu.ItemGroup>
        <Link to={`/profile/`} style={{ color: "inherit", textDecoration: "none" }}>
          <Row align='middle'>
            <Col style={{ textAlign: 'center' }} span={6}>
              <FontAwesomeIcon style={{ width: '24px', height: '24px' }} icon={faUserCircle} />
            </Col>
            <Col span={18}>
              <Row><Text strong>{identity?.first_name} {identity?.last_name}</Text></Row>
              <Row><Text type="secondary">{identity?.username}</Text></Row>
            </Col>
          </Row>
        </Link>
        <Divider style={{ margin: '4px 0' }} />
        <div style={{ padding: '4px 12px' }}>
          <Text type="secondary" style={{ fontSize: '12px' }}>ACTIVE TEAM</Text>
        </div>
        <ActivateMembership />
        <Divider style={{ margin: '4px 0' }} />
        {feedback && feedback?.ENABLED &&
          <Menu.Item key="3">
            <a href={feedback.config?.feedback_link} target="_blank">Report a problem</a>
          </Menu.Item>
        }
        <Menu.Item key="2" onClick={() => setIsLicenseModalOpen(true)}>
          Software Licenses
          <Modal
            title="License Attributions"
            visible={isLicenseModalOpen}
            onCancel={(e) => { e.stopPropagation(); setIsLicenseModalOpen(false) }}
            footer={null}
          >
            {isLicenseModalOpen && <Licenses />}
          </Modal>

        </Menu.Item>
        <Menu.Item key="1" onClick={logout} icon={<LogoutOutlined />}>
          Log Out
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )


  return (
    <Header className="header">
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumbs path={pathName} />
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => toggleSidebar()}
          >
            <MenuOutlined />
          </Button>

          <Dropdown overlay={userMenu} trigger={["click"]}>
            <Space>
                <span>
                  {activeTeamName}
                </span>

                <a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>

                  <FontAwesomeIcon style={{ width: '24px', height: '24px' }} icon={faUserCircle} />

                </a>
            </Space>
          </Dropdown>

          <Tooltip title="Getting Started">
            <Button onClick={() => setShowWelcomeScreen(true)} type="link" shape="circle">
              <QuestionCircleOutlined />
            </Button>
          </Tooltip>
          <SearchBar style={{ width: "25%", marginRight: "5px" }} />
        </Col>
      </Row>
      {showWelcomeScreen && <WelcomeScreen onClose={() => setShowWelcomeScreen(false)} />}
    </Header>

  )
}
export default AppHeader;
