import {useDataProvider, useNotify, useQueryWithStore} from "react-admin";
import React, {useState} from "react";
import {Menu, Space, Collapse, Typography, Badge, Row, Col, List, Divider} from "antd";
import {ExtendedDataProvider} from "../../../types";
import {faUserFriends} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TeamCreateModal from "./TeamCreateModal";
import {getActiveMembership, setLocalStorageForUser} from "@src/components/Common/utils";

const { Panel } = Collapse;
const { Text } = Typography;

const ActivateMembership = () => {
    const notify = useNotify();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const activeMembership = getActiveMembership()
    const activeTeamName =  activeMembership?.team?.name

    const { loaded, error, data: membershipsData } = useQueryWithStore({
        type: 'getFilterOptions',
        resource: 'user_memberships',
        payload: {
            pagination: { page: 1, perPage: 20 },
            sort: { field: 'id', order: 'asc' },
            filter: {}
        }
    });

    const handleActiveTeamChange = (e:any) => {
        if(e.key==='null')
            return

        if(e)
            dataProvider.getAction('user_memberships', { id: e.key, verb: 'activate' })
                .then((response: any) => {
                    notify('Active team changed')
                    setLocalStorageForUser(response.data, null)
                    window.location.reload()
                })
                .catch((error: any) => notify(JSON.stringify(error.body), 'error'))
    }

    const header = (
        <Space align="center" style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '20px', minWidth: '20px', textAlign: 'center'}}>
                <FontAwesomeIcon icon={faUserFriends} style={{ width: '20px', height: '20px' }} />
            </div>
            <div style={{ 
                flex: '1 1 auto',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                paddingRight: '32px',
             }}>
                <Text strong>
                    {activeTeamName || 'Select a team'}
                </Text>
            </div>
        </Space>
    );

    const shouldShowScrollbar = (membershipsData?.length || 0) > 5;
    const listHeight = shouldShowScrollbar ? '250px' : 'auto';

    return (
        <>
            <Collapse 
                ghost 
                bordered={false}
                expandIconPosition="right"
                style={{ margin: 0 }}
            >
                <Panel 
                    header={header} 
                    key="1"
                    style={{ padding: 0 }}
                >
                    <div style={{ position: 'relative' }}>
                        <List
                            style={{ 
                                height: listHeight, 
                                overflow: shouldShowScrollbar ? 'auto' : 'hidden',
                                border: 'none', 
                                backgroundColor: 'transparent',
                                scrollbarGutter: 'stable',
                            }}
                            dataSource={membershipsData || []}
                            split={false}
                            renderItem={(mData: any) => (
                                <List.Item 
                                    key={mData.id}
                                    onClick={() => handleActiveTeamChange({ key: mData.id })}
                                    style={{
                                        height: '32px',
                                        lineHeight: '32px',
                                        padding: '0 24px',
                                        backgroundColor: mData.id === activeMembership?.id ? '#e6f7ff' : 'transparent',
                                        position: 'relative',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Text style={{ 
                                        color: mData.id === activeMembership?.id ? '#1890ff' : 'inherit',
                                        fontWeight: mData.id === activeMembership?.id ? 500 : 400 
                                    }}>
                                        {mData.name}
                                    </Text>
                                    {mData.id === activeMembership?.id && (
                                        <Badge 
                                            color="blue" 
                                            style={{ 
                                                position: 'absolute',
                                                right: '12px',
                                                top: '50%',
                                                transform: 'translateY(-50%)'
                                            }} 
                                        />
                                    )}
                                </List.Item>
                            )}
                            footer={
                                <>
                                    <Divider style={{ margin: '4px 0' }} />
                                    <List.Item 
                                        onClick={() => setIsModalOpen(true)}
                                        style={{
                                            height: '32px',
                                            lineHeight: '32px',
                                            padding: '0 16px',
                                            color: '#1890ff',
                                            fontWeight: 500,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        + Create a new team
                                    </List.Item>
                                </>
                            }
                        />
                        {shouldShowScrollbar && (
                            <div style={{
                                position: 'absolute',
                                bottom: '0px',
                                left: 0,
                                right: 0,
                                height: '32px',
                                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
                                pointerEvents: 'none',
                                zIndex: 1
                            }} />
                        )}
                    </div>
                </Panel>
            </Collapse>
            <TeamCreateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </>
    );
}

export default ActivateMembership;
