import { AuthProvider, fetchUtils } from 'ra-core';
import {setLocalStorageForUser} from "@src/components/Common/utils";

export interface Options {
  obtainAuthTokenUrl?: string;
}

function tokenAuthProvider(options: Options = {}): AuthProvider {
  const opts = {
    obtainAuthTokenUrl: `${process.env.API_URL}/api-token-auth/`,
    ...options,
  };
  return {
    login: async ({ username, password }) => {
      const request = new Request(opts.obtainAuthTokenUrl, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      const response = await fetch(request);
      if (response.ok) {
        const data = await response.json()
        localStorage.setItem('user', JSON.stringify(data.user));
        setLocalStorageForUser(data.active_membership, data.token)
        localStorage.setItem('general_settings', JSON.stringify(data.general_settings));
        return;
      }
      if (response.headers.get('content-type') !== 'application/json') {
        throw new Error(response.statusText);
      }

      const json = await response.json();
      const error = json.non_field_errors;
      throw new Error(error || response.statusText);
    },
    logout: async () => {
      const token = localStorage.getItem('token');
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      const request = new Request(`${process.env.API_URL}/logout/token/`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + token
        }),
      });
      const response = fetch(request);
      return Promise.resolve();
    },
    checkAuth: () =>
      localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
    checkError: error => {
      const status = error.status;
      if (status === 401) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    getPermissions: () => {
      const role = localStorage.getItem('role');
      return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () => {
      try {
          const { id, username, email, is_superuser, first_name, last_name } = JSON.parse(localStorage.getItem('user')!);
          return Promise.resolve({ id, first_name, last_name, username, email, is_superuser });
      } catch (error) {
          return Promise.reject(error);
      }
  },
    // @ts-ignore
    loginGoogle: async (google_credential) => {
      const data = {
        "auth_token": google_credential,
        "id": 1
      }
      const request = new Request(`${process.env.API_URL}/login/google/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({'Content-Type': 'application/json'}),
      });
      const response = await fetch(request);
      if (response.ok) {
        const data = await response.json()

        localStorage.setItem('user', JSON.stringify({...data.user}));
        localStorage.setItem('token', data.token);
        localStorage.setItem('general_settings', JSON.stringify(data.general_settings));
        return
      }
      if (response.headers.get('content-type') !== 'application/json') {
        throw new Error(response.statusText);
      }
    }
  };
}

export function createOptionsFromToken() {
  const token = localStorage.getItem('token');
  if (!token) {
    return {};
  }
  return {
    user: {
      authenticated: true,
      token: 'Token ' + token,
    },
  };
}

export function fetchJsonWithAuthToken(url: string, options: object) {
  return fetchUtils.fetchJson(
    url,
    Object.assign(createOptionsFromToken(), options)
  );
}

export default tokenAuthProvider;
