import {Space, Tag, Typography} from "antd";
import {renderItemDeclaration, renderItemEvaluationResults} from "@src/components/AIValidators/SubComponents/utils";
import EvaluationResponseButton from "@src/components/AIValidators/SubComponents/EvaluationResponseButton";
import React from "react";

const { Text } = Typography;


const EvaluationRowPanel = ({index,  item, evaluationResults }: {index: number, item: any, evaluationResults: any}) => {
    return (
        <Space direction={"horizontal"} align={"start"} style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            {renderItemDeclaration(item.declaration)}
            <Space direction={"vertical"} align={"end"}>
                <Space direction={"horizontal"}>
                    {"Expected Response: "}
                    {
                        // @ts-ignore
                        item.is_valid?<Tag color="#69c0ff">APPROVED</Tag>: <Tag color="orange">REJECTED</Tag>
                    }
                </Space>
                {
                    !(item?.is_new) && evaluationResults?.state=="OK"?
                        <Space direction={"vertical"} align={"end"}>
                            <Space direction={"horizontal"}>Last run:{renderItemEvaluationResults(index, evaluationResults)}</Space>
                            <EvaluationResponseButton AIResponseID={evaluationResults["results"][index]["id"]}/>
                        </Space>
                        :
                        <Text code style={{fontSize: "14px", marginRight: "5px"}}>Not evaluated yet</Text>
                }
            </Space>
        </Space>
    )

}


export default EvaluationRowPanel;
