interface ServiceItem {
    applicationName: string;
    name: string;
    serviceName: string;
    data?: any;
}
type UseDeclarationDataEditor = {
    getServiceItemData: (applicationName: string | null, serviceName: string, serviceItemName: string, declarationData: any) => ServiceItem;
    partialUpdateDeclaration: (params: { applicationName: string | null; serviceName: string; serviceItemName: string; updatedData: any; }) => ServiceItem;
    partialDeleteDeclaration: (params: { applicationName: string | null; serviceName: string; serviceItemName: string }) => void;
    partialUpdateAppMetadata: (params: { applicationName: string; updatedData: any }) => void;
    partialUpdateTeamMetadata: (params: { updatedData: any }) => void;
    deleteAppFromDeclaration: (params: { applicationName: string }) => void;
    renameAppInDeclaration: (params: { applicationName: string, updatedApplicationName: string }) => void;
};

const useDeclarationDataEditor = ({ declarationData, setDeclarationData }: { declarationData: any | null, setDeclarationData: Function}): UseDeclarationDataEditor => {

    const getServiceItemData = (applicationName: string | null, serviceName: string, serviceItemName: string, declarationData: any): ServiceItem => {
        if (!applicationName) {
            throw new Error(`App name/team name cant be null`);
        }
        const teamName = Object.keys(declarationData)[0]

        for (const [type, items] of Object.entries(declarationData[teamName][applicationName]?.services)) {
            if (Array.isArray(items) && type == serviceName) {
                const foundItem = items.find((item: any) => item.name === serviceItemName);
                if (foundItem) {
                    return {
                        applicationName: applicationName,
                        name: foundItem.name,
                        serviceName: type,
                        data: foundItem
                    }
                }
            }
        }
        throw new Error(`Service Item '${serviceItemName}' not found.`);
    };

    const partialUpdateDeclaration = ({ 
        applicationName, 
        serviceName, 
        serviceItemName, 
        updatedData,
    }: { 
        applicationName: string | null, 
        serviceName: string, 
        serviceItemName: string, 
        updatedData: any,
    }) => {
        console.log("UPD: ", applicationName, serviceName, serviceItemName, updatedData)
        let serviceItemLookupName = serviceItemName
        if (!(declarationData)) {
            console.error(declarationData)
            throw new Error('Declaration data is missing');
        }

        if (!applicationName) {
            throw new Error(`App name cant be null`);
        }

        // if the name doesnt match the name field in the form data then the name has been updated
        if (serviceItemName != updatedData?.name) {
            serviceItemLookupName = updatedData?.name
        }

        const teamName = Object.keys(declarationData)[0]

        const tempData = JSON.parse(JSON.stringify(declarationData));
        if (!tempData[teamName][applicationName].services[serviceName]) {
            // If itemType doesn't exist, create a new entry for it
            tempData[teamName][applicationName].services[serviceName] = [];
        }
        const itemIndex = tempData[teamName][applicationName].services[serviceName].findIndex((item: any) => item.name === serviceItemName);

        if (itemIndex === -1) {
            tempData[teamName][applicationName].services[serviceName].push({ name: serviceItemName, ...updatedData });
        }
        else {
            tempData[teamName][applicationName].services[serviceName][itemIndex] = updatedData;
        }
        setDeclarationData(tempData)
        console.log(`${serviceName} "${serviceItemName}" updated successfully!`);

        const serviceItem: ServiceItem = getServiceItemData(applicationName, serviceName, serviceItemLookupName, tempData)
        

        return serviceItem
    }
    const renameAppInDeclaration = ({ applicationName, updatedApplicationName }: { applicationName: string, updatedApplicationName: string }) => {

        if (!(declarationData && applicationName)) {
            console.error("missing data")
        }

        if (applicationName === updatedApplicationName) {
            return;
        }

        const teamName = Object.keys(declarationData)[0]
        if (!declarationData[teamName][applicationName]) {
            console.error('Missing data');
            return;
        }
        const updatedServiceItemsData = JSON.parse(JSON.stringify(declarationData));
        updatedServiceItemsData[teamName][updatedApplicationName] = updatedServiceItemsData[teamName][applicationName];
        delete updatedServiceItemsData[teamName][applicationName];
        setDeclarationData(updatedServiceItemsData)

    }



    const deleteAppFromDeclaration = ({ applicationName }: { applicationName: string }) => {

        if (!(declarationData && applicationName)) {
            console.error("missing data")
        }
        const teamName = Object.keys(declarationData)[0]
        if (!declarationData[teamName][applicationName]) {
            console.error('Missing data');
            return;
        }


        const updatedServiceItemsData = JSON.parse(JSON.stringify(declarationData));
        delete updatedServiceItemsData[teamName][applicationName];
        setDeclarationData(updatedServiceItemsData)

    }

    const partialUpdateAppMetadata = ({ applicationName, updatedData }: { applicationName: string, updatedData: any }) => {
        if (!declarationData || !applicationName) {
            console.error('Missing data or selectedApplication');
            return;
        }

        const teamName = Object.keys(declarationData)[0]
        if (!declarationData[teamName][applicationName]) {
            console.error('Missing data');
            return;
        }

        const tempData = JSON.parse(JSON.stringify(declarationData));
        if (!tempData[teamName][applicationName].metadata) {
            // If itemType doesn't exist, create a new entry for it
            tempData[teamName][applicationName].metadata = {};
        }
        tempData[teamName][applicationName].metadata = updatedData;
        setDeclarationData(tempData)
    }

    const partialUpdateTeamMetadata = ({ updatedData }: { updatedData: any }) => {
        if (!declarationData) {
            console.error('Missing data or selectedApplication');
            return;
        }

        const teamName = Object.keys(declarationData)[0]
        if (!declarationData[teamName]) {
            console.error('Missing data');
            return;
        }

        const tempData = JSON.parse(JSON.stringify(declarationData));
        if (!tempData[teamName].metadata) {
            // If itemType doesn't exist, create a new entry for it
            tempData[teamName].metadata = {};
        }
        tempData[teamName].metadata = updatedData;
        setDeclarationData(tempData)
    }

    function partialDeleteDeclaration({ applicationName, serviceName, serviceItemName }: { applicationName: string | null, serviceName: string, serviceItemName: string }) {

        if (!(declarationData)) {
            console.error(declarationData)
            return;
        }

        const teamName = Object.keys(declarationData)[0]
        // Ensure data and application exist
        if (!applicationName || !teamName) {
            console.error('Missing teamName or selectedApplication');
            return;
        }

        const itemIndex = declarationData[teamName][applicationName].services[serviceName]?.findIndex((item: any) => item.name === serviceItemName);

        // Ensure itemType and item exist
        if (itemIndex === undefined) {
            console.error(`Item '${serviceItemName}' (type '${serviceName}') not found.`);
            return;
        }
        const updatedServiceItemsData = JSON.parse(JSON.stringify(declarationData));

        // Delete the item
        updatedServiceItemsData[teamName][applicationName].services[serviceName].splice(itemIndex, 1);
        // Delete the item type if there arent any items remaining
        if (updatedServiceItemsData[teamName][applicationName].services[serviceName].length === 0) {
            delete updatedServiceItemsData[teamName][applicationName].services[serviceName];
        }

        console.log(`${serviceName} "${serviceItemName}" deleted successfully!`);
        setDeclarationData(updatedServiceItemsData)

    }

    return {
        getServiceItemData,
        partialUpdateDeclaration,
        partialDeleteDeclaration,
        partialUpdateTeamMetadata,
        partialUpdateAppMetadata,
        deleteAppFromDeclaration,
        renameAppInDeclaration
    }
}

export default useDeclarationDataEditor;