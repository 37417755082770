import useGetGeneralSettings from "@src/providers/useGetGeneralSettings";

export const isAIValidatorVisible = () => {
    const generalSettings = useGetGeneralSettings();
    const AIValidatorSettings = generalSettings.find(setting => setting.name == 'AIValidator');
    return AIValidatorSettings?.config?.show_ai_validator == true;
}


export const getVisibility = (GeneralSettingsName: string) => {
    if(GeneralSettingsName=="AIValidator")
        return isAIValidatorVisible()

    // By default, other Resources are visible from GeneralSettings
    return true
}
