import {Card, Spin} from "antd";
import ReactJson from "react-json-view";
import React from "react";
import {useQuery} from "react-admin";

const TeamMetadataCard = ({teamId}: {teamId: number}) => {
    const { data: team, loading, error } = useQuery({
        type: 'getOne',
        resource: 'teams',
        payload: { id: teamId },
    });
    const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>


    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Team Metadata"
        >
            {
                team?
                    <ReactJson
                        style={{ padding: '20px' }}
                        src={team.metadata}
                        displayArrayKey={false}
                        collapsed={false}
                        displayDataTypes={false}
                        name="team_metadata"
                        displayObjectSize={false}
                    />
                    :
                    spinnerElement
            }
        </Card>
    )
}


export default TeamMetadataCard;
