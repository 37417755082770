import {useDataProvider, useNotify, useQuery, useRefresh} from "react-admin";
import React from "react";
import {Button, Form, Switch} from "antd";
import {createSubmitHandler} from "@src/components/Settings/utils";
import {ExtendedDataProvider} from "@src/types";


const ServiceCreatorSettingsForm = () => {
    const notify = useNotify ()
    const [form] = Form.useForm ();
    const refresh = useRefresh();
    const dataProvider: ExtendedDataProvider = useDataProvider ()
    const settingsName = "ServiceCreator"

    const {loaded, error, data: currentSettings} = useQuery ({
        type: 'getOne',
        resource: 'general_settings',
        payload: {id: settingsName}
    });

    return (
        <>
            {
                (loaded || error) &&
                <Form
                    form={form}
                    labelAlign='left'
                    labelCol={{span: 10}}
                    wrapperCol={{span: 16}}
                    onFinish={createSubmitHandler(settingsName, currentSettings, dataProvider, notify, refresh)}
                >
                    <Form.Item
                        label={"Show Create Service Button"}
                        initialValue={currentSettings?.['config']?.show_create_service_option || false}
                        name="show_create_service_option"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.show_create_service_option == true}/>
                    </Form.Item>

                    <Form.Item
                        label={"Enable Create Service Functionality"}
                        initialValue={currentSettings?.['config']?.allow_service_creation || false}
                        name="allow_service_creation"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.allow_service_creation == true}/>
                    </Form.Item>

                    <Form.Item
                        label={"Default Allow Create Service Functionality"}
                        initialValue={currentSettings?.['config']?.default_allow_service_creation || false}
                        name="default_allow_service_creation"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.default_allow_service_creation == true}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="form-button">Save</Button>
                    </Form.Item>
                </Form>
            }
        </>
    )
}

export default ServiceCreatorSettingsForm;
