import useGetGeneralSettings from "../../../providers/useGetGeneralSettings";
import {getActiveMembership} from "@src/components/Common/utils";


export const isSubmitButtonVisible = () => {
    const generalSettings = useGetGeneralSettings();
    const activeMembership = getActiveMembership()

    const submissionBuilderSettings = generalSettings.find(setting => setting.name == 'SubmissionBuilder');
    const visibleFromGeneralSettings = submissionBuilderSettings?.config?.enable_submit_functionality
    const visibleFromTeamPreferences = activeMembership?.team?.preferences?.allow_submit_submission_builder

    return visibleFromGeneralSettings && visibleFromTeamPreferences
}
