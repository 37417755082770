export const defaultReadmeTemplate = `
# [Service Name] Service Request  

## Overview  
This service will [briefly describe what the service does] for the requesting application.  

## Details  
- All fields in the request are mandatory and must pass format validation.  
- Requests that do not meet validation criteria will not be processed.  

## Implementation  
- A request to the [Service Code] service will trigger [describe actions, such as spawning change instances, triggering workflows, etc.].  
- These will be treated as separate tasks, and all must be completed to ensure synchronization with deployment.  

## Pricing  
- **Free Tier**: [Describe any free usage limits, if applicable]  
- **Standard Tier**: [Provide cost details, such as per-request or subscription pricing]  
- **Enterprise Tier**: [Mention any enterprise pricing and custom plans]  

## Support  
- **Contact**: [List contact details or links for support inquiries]  

## Tracking Progress  
Please use the NetOrca GUI to monitor the status of your request and track individual change instances.  

`; 