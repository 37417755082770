import ReactJson from "react-json-view";
import {Tag} from "antd";
import React from "react";

export const renderItemDeclaration = (declaration: any) => {
    const decStr = typeof declaration !== "string"?JSON.stringify(declaration) : declaration;
    const decJson = typeof declaration === "string"?JSON.parse(declaration) : declaration;

    return  <ReactJson
        style={{ paddingLeft: '20px', paddingRight: "20px" }}
        src={decJson}
        displayDataTypes={false}
        name={null}
        displayArrayKey={false}
        displayObjectSize={false}
        collapsed={false}
    />
}


export const renderItemEvaluationResults = (index: number, evaluationResults: any) => {
    if(evaluationResults["state"] === "OK")
        return <>
            {
                evaluationResults["results"][index]["succeeded"]?
                    <Tag color="#87d068">PASSED</Tag>
                    :
                    <Tag color="#f50">FAILED</Tag>
            }
        </>
    return <></>
}
