import React, { useEffect, useState } from 'react';
import { Button, Space, Typography, message } from 'antd';
    import MonacoEditor from '@monaco-editor/react';
import { ServiceField, ServiceCreationState, useServiceCreation } from '../contexts/ServiceCreationContext';
import { useDataProvider } from 'ra-core';
import useGetGeneralSettings from "@src/providers/useGetGeneralSettings";
import {getActiveMembership} from "@src/components/Common/utils";


const { Title } = Typography;

export const SchemaEditor: React.FC = () => {
  const { state, setJsonSchema, prevStep, nextStep, setServiceId } = useServiceCreation();
  const dataProvider = useDataProvider();
  const [isValidated, setIsValidated] = useState(false);
  
  const generalSettings = useGetGeneralSettings();

  const isSubmitButtonVisible = () => {
    const activeMembership = getActiveMembership()

    const serviceCreatorSettings = generalSettings.find(setting => setting.name == 'ServiceCreator');
    const visibleFromGeneralSettings = serviceCreatorSettings?.config?.allow_service_creation
    const visibleFromTeamPreferences = activeMembership?.team?.preferences?.allow_service_creation

    return visibleFromGeneralSettings && visibleFromTeamPreferences
}

  // Reset validation status when schema changes
  useEffect(() => {
    setIsValidated(false);
  }, [state.jsonSchema]);

  // Generate JSON Schema whenever properties or basic info changes
  useEffect(() => {
    // If no JSON schema exists or if we're in custom mode (has selected properties)
    if (!state.jsonSchema || state.selectedProperties.length > 0) {
      const schema = generateJsonSchema(state.selectedProperties, state.basicInfo);
      setJsonSchema(schema);
    }
  }, [state.selectedProperties, state.basicInfo]);

  const handleEditorChange = (value: string | undefined) => {
    setJsonSchema(value || '');
  };

  const handleValidate = async () => {
    try {
      await dataProvider.createAction('services', { 
        data: JSON.parse(state.jsonSchema),
        verb: 'validate/' 
      });
      message.success('Schema validation successful');
      setIsValidated(true);
    } catch (error: any) {
      setIsValidated(false);
      // Try to parse the error response
      let errorMessage = 'Schema validation failed';
      
      if (error.body) {  // If error has a response body
        try {
          const errorData = error.body;
          if (errorData.errors?.schema) {
            // Extract specific validation errors
            errorMessage = Object.values(errorData.errors.schema).join(', ');
          } else if (errorData.errors?.object) {
            // Handle object-level errors (e.g., duplicate service)
            errorMessage = errorData.errors.object;
          } else if (typeof errorData.errors === 'object') {
            // Handle any other error object
            errorMessage = Object.values(errorData.errors).join(', ');
          }
        } catch (e) {
          // If parsing fails, use the original error message
          errorMessage = error.message;
        }
      }
      
      message.error(errorMessage);
    }
  };

  const handleSubmit = async () => {
    try {
      const response  = await dataProvider.create('services', { 
        data: JSON.parse(state.jsonSchema),
      });
      message.success('Service created successfully');
      setServiceId(Number(response.data.id));
      nextStep(); // Move to readme step
    } catch (error) {
      message.error('Failed to create service: ' + (error as Error).message);
    }
  };

  return (
    <div>
      <Title level={4}>JSON Schema Editor</Title>
      <div style={{ border: '1px solid #d9d9d9', borderRadius: '2px', marginBottom: '16px' }}>
        <MonacoEditor
          height="400px"
          language="json"
          theme="light"
          value={state.jsonSchema}
          onChange={handleEditorChange}
          options={{
            minimap: { enabled: false },
            formatOnPaste: true,
            formatOnType: true,
            automaticLayout: true,
          }}
        />
      </div>
      <Space>
        <Button onClick={prevStep}>
          Previous
        </Button>
        <Button onClick={handleValidate}>
          Validate Schema
        </Button>
        {isSubmitButtonVisible() && (
          <Button 
            type="primary" 
            onClick={handleSubmit}
            disabled={!isValidated}
          >
            Create Service
          </Button>
        )}
      </Space>
    </div>
  );
};

function generateJsonSchema(properties: ServiceField[], basicInfo: ServiceCreationState['basicInfo']) {
  const schema = {
    $schema: "https://json-schema.org/draft/2020-12/schema",
    $id: "https://example.com/service.schema.json",
    title: basicInfo.name,
    description: basicInfo.description,
    type: "object",
    additionalProperties: false,
    metadata: {
      cost_per_change: 0,
      monthly_cost: 0
    },
    required: ["name"] as string[],
    properties: {
      name: {
        type: "string"
      }
    } as Record<string, any>
  };

  properties.forEach(prop => {
    console.log(prop)
    const propertyName = prop.name.toLowerCase().replace(/\s+/g, '_');
    
    if (prop.required) {
      schema.required.push(propertyName);
    }

    // Use the schema directly from the property template
    schema.properties[propertyName] = prop.schema;
  });

  return JSON.stringify(schema, null, 2);
}