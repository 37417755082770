import React from 'react';
import { Form, Input, Button } from 'antd';
import { useServiceCreation } from '../contexts/ServiceCreationContext';

export const BasicInfoForm: React.FC = () => {
  const { state, setBasicInfo, nextStep } = useServiceCreation();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    setBasicInfo({
      name: values.name,
      description: values.description,
      isPrivate: values.isPrivate || false,
    });
    nextStep();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        name: state.basicInfo.name,
        description: state.basicInfo.description,
        isPrivate: state.basicInfo.isPrivate,
      }}
    >
      <Form.Item
        name="name"
        label="Service Name"
        rules={[
          { required: true, message: 'Please input the service name' },
          { max: 50, message: 'Service name cannot exceed 50 characters' },
        ]}
      >
        <Input placeholder="Enter service name" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          { required: true, message: 'Please input the service description' },
          { max: 500, message: 'Description cannot exceed 500 characters' },
        ]}
      >
        <Input.TextArea 
          rows={4} 
          placeholder="Describe what this service does"
        />
      </Form.Item>


      <Form.Item>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  );
}; 