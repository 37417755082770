import { EditOutlined } from '@ant-design/icons';
import MonacoEditor from '@monaco-editor/react';
import { ExtendedDataProvider } from "@src/types";
import { Button, Card, Modal } from "antd";
import React, { useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from "react-admin";
import ReactJson from "react-json-view";
import { isVisibleCreateServiceButton } from './utils';

interface ServiceRowSchemaTabProps {
    record: Record<string, any>;
}

const ServiceRowSchemaTab: React.FC<ServiceRowSchemaTabProps> = ({ record }) => {
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const notify = useNotify();
    const [isSchemaModalVisible, setIsSchemaModalVisible] = useState(false);
    const [schemaValue, setSchemaValue] = useState('');
    const [isValidated, setIsValidated] = useState(false);
    const refresh = useRefresh();


    const handleEditorChange = (value: string | undefined) => {
        setSchemaValue(value || '');
        setIsValidated(false);
    };

    const handleValidate = async () => {
        try {
            await dataProvider.createAction('services', {
                data: JSON.parse(schemaValue),
                verb: 'validate/'
            });
            notify('Schema validation successful', 'success');
            setIsValidated(true);
        } catch (error: any) {
            setIsValidated(false);
            let errorMessage = 'Schema validation failed';

            if (error.body) {
                try {
                    const errorData = error.body;
                    if (errorData.errors?.schema) {
                        errorMessage = Object.values(errorData.errors.schema).join(', ');
                    } else if (errorData.errors?.object) {
                        errorMessage = errorData.errors.object;
                    } else if (typeof errorData.errors === 'object') {
                        errorMessage = Object.values(errorData.errors).join(', ');
                    }
                } catch (e) {
                    errorMessage = error.message;
                }
            }

            notify(errorMessage, 'error');
        }
    };

    const handleUpdateSchema = async () => {
        try {
            await dataProvider.create('services', {
                data: JSON.parse(schemaValue),
            });
            notify('Schema updated successfully', 'success');
            setIsSchemaModalVisible(false);
            refresh(); // This will trigger a refresh of the datas
        } catch (error: any) {
            notify('Failed to update schema: ' + error.message, 'error');
        }
    };

    const showSchemaModal = () => {
        setSchemaValue(JSON.stringify(record.schema.schema, null, 2));
        setIsValidated(false);
        setIsSchemaModalVisible(true);
    };

    return (
        <Card extra={
            isVisibleCreateServiceButton() && <Button
                onClick={showSchemaModal}
                icon={<EditOutlined />} 
                style={{ marginBottom: '16px' }}
            >
                Edit Schema
        </Button>}>

            <ReactJson
                style={{ padding: '20px' }}
                src={record.schema.schema}
                displayDataTypes={false}
                name="schema"
                displayArrayKey={false}
                displayObjectSize={false}
            />
            <Modal
                title="Edit Schema"
                visible={isSchemaModalVisible}
                onCancel={() => setIsSchemaModalVisible(false)}
                width={800}
                footer={[
                    <Button key="cancel" onClick={() => setIsSchemaModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="validate" onClick={handleValidate}>
                        Validate Schema
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleUpdateSchema}
                        disabled={!isValidated}
                    >
                        Update Schema
                    </Button>
                ]}
            >
                <div style={{ border: '1px solid #d9d9d9', borderRadius: '2px' }}>
                    <MonacoEditor
                        height="400px"
                        language="json"
                        theme="light"
                        value={schemaValue}
                        onChange={handleEditorChange}
                        options={{
                            minimap: { enabled: false },
                            formatOnPaste: true,
                            formatOnType: true,
                            automaticLayout: true,
                        }}
                    />
                </div>
            </Modal>
        </Card>
    );
};

export default ServiceRowSchemaTab; 