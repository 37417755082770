import React, {useEffect, useState} from "react";
import {Button, Card, Modal, Spin} from "antd";
import {ExtendedDataProvider} from "@src/types";
import {useDataProvider} from "react-admin";
import ReactJson from "react-json-view";


const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>


const EvaluationResponseButton = ({AIResponseID}: {AIResponseID: number}) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const dataProvider: ExtendedDataProvider = useDataProvider();

    useEffect(() => {
        if (visible) {
            dataProvider.getOne ('serviceowner_ai_validator_responses', {id: AIResponseID})
                .then ((data: any) => {setData(data.data); setLoading(false)})
                .catch ((error: any) => console.log (error))
        }
    }, [visible])

    const handleClick = async () => {
        setLoading(true);
        setVisible(true)
    };

    return (
        <>
            <Button style={{lineHeight: "0px", fontSize: "12px", marginRight: "5px"}} size={"small"} onClick={handleClick}>
                View AI Response
            </Button>

            <Modal title="AI Response" width={1000} visible={visible} onCancel={() => setVisible(false)} footer={null}>
                {
                    loading?
                        spinnerElement
                        :
                        data ?
                            <>
                                <ReactJson
                                    style={{ paddingLeft: '20px', paddingRight: "20px" }}
                                    src={
                                        // @ts-ignore
                                        data.body
                                    }
                                    displayDataTypes={false}
                                    name={null}
                                    displayArrayKey={false}
                                    displayObjectSize={false}
                                    collapsed={false}
                                />
                            </>
                            :
                            <p>No data available</p>
                }
            </Modal>
        </>
    )
}

export default EvaluationResponseButton;