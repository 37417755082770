import {useQuery} from "react-admin";
import SkeletonTable, {SkeletonTableColumnsType} from "../../Common/SkeletonTable";
import {ChangeInstanceMiniColumns} from "../../Common/Columns";
import {Card, Spin, Table} from "antd";
import LazyExpandedRow from "../../Common/LazyExpandedRow";
import ConsumerChangeInstanceExpandedRow from "../../ChangeInstances/ChangeInstanceRow";
import {onExpandOnlyOneRow} from "../../Common/tableUtils";
import React, {useState} from "react";


const ChangeInstanceExpandedTable = ({ resource, filterParam }: { resource: string, filterParam: Record<string, string|boolean> }) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);


    const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>

    const { data, loaded, total } = useQuery({
        type: 'getList',
        resource: resource,
        payload: {
            pagination: { page, perPage },
            sort: { field: 'id', order: 'DESC' },
            filter: filterParam
        }
    });

    return (
        loaded?
            <SkeletonTable columns={ChangeInstanceMiniColumns as SkeletonTableColumnsType[]}>
                <Table
                    rowKey="id"
                    columns={ChangeInstanceMiniColumns}
                    dataSource={data}
                    pagination={{
                        onShowSizeChange: (_current, size) => setPerPage(size),
                        showSizeChanger: true,
                        pageSizeOptions: [20, 50, 100],
                        total: total,
                        pageSize: perPage,
                        current: page,
                        onChange: page => setPage(page)
                    }}
                    expandedRowKeys={expandedRowKeys}
                    expandable={{
                        expandedRowRender: (record: any) => <LazyExpandedRow lightRecord={record} resource={"change_instances"} ExpandedRowComponent={ConsumerChangeInstanceExpandedRow}/>,
                        expandRowByClick: true,
                        onExpand: onExpandOnlyOneRow(setExpandedRowKeys)
                    }}
                    style={{margin: "5px"}}
                />
            </SkeletonTable>
            :
            spinnerElement
    )
}

export default ChangeInstanceExpandedTable;
