import * as React from "react";
import {ListBase, ListProps, Record, useDataProvider, useGetIdentity, useListContext, useNotify, usePermissions} from "react-admin";
import {CheckCircleOutlined, CloseCircleOutlined, EditOutlined, SyncOutlined} from "@ant-design/icons";
import {Button, Card, Switch, Table, Tabs, Tag} from "antd";
import {ExtendedDataProvider} from "@src/types";
import {Link} from "react-router-dom";
import SkeletonTable, {SkeletonTableColumnsType} from "../Common/SkeletonTable";
import LLMModelsList from "@src/components/LLMModels/LLMModelsList";
import {getTagForEvaluation} from "@src/components/Common/Tags";

const { TabPane } = Tabs;


const AIValidatorListView = () => {
  const { ids, data, loading, total, perPage, setPage, page, refetch } = useListContext();
  const dataProvider: ExtendedDataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const triggerAIValidator = (record: any) => {
    dataProvider.getAction('serviceowner_ai_validators', { id: record.id, verb: 'trigger' })
        .then((response: any) => notify('AI validation is triggered'))
        .catch((error: any) => notify(JSON.stringify(error.body), 'error'))
  }

  const evaluateAIValidator = (record: any) => {
    dataProvider.getAction('serviceowner_ai_validators', { id: record.id, verb: 'evaluate' })
        .then((response: any) => {
          refetch()
          notify('AI validation is evaluation is started')
        })
        .catch((error: any) => notify(JSON.stringify(error.body), 'error'))
  }

  const enableAIValidator = (record: any, checked: boolean) => {
    dataProvider.update('serviceowner_ai_validators', {
      id: record.id,
      data: { active: checked, service: record.service.id, llm_model: record.llm_model.id, prompt: record.prompt },
      previousData: { id: record.id }
    })
        .then(data => {
          refetch()
          notify('AI Validator ' + (checked? "Enabled": "Disabled"));
        })
        .catch((error: any) => {
          if (error.status == 400)
            notify('Error updating webhook', 'error')
        })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (<>
        <div style={{ display: 'grid', placeItems: 'stretch' }}>
          <Link style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} to={`/serviceowner_ai_validators/${record.id}`}>
            {text + "  "}{getTagForEvaluation(record.evaluation_result)}
          </Link>
        </div>

      </>)
    },
    {
      title: 'LLM Model',
      dataIndex: ['llm_model', 'name'],
      key: 'llm_model',
    },
    {
      title: 'Service',
      dataIndex: ['service', 'name'],
      key: 'service',
    },
    {
      render: (text: any, record: any) =>
        <div>
          {
              permissions === "ADMIN" &&
              <>
                <Button style={{ marginRight: '5px'}} onClick={() => triggerAIValidator(record)}>Trigger</Button>
                <Button style={{ marginRight: '5px'}} onClick={() => evaluateAIValidator(record)}>Evaluate</Button>
              </>
          }
          {
              permissions === "ADMIN" &&
              <Switch
                  checkedChildren="Enabled"
                  unCheckedChildren="Disabled"
                  defaultChecked={record.active}
                  onChange={(checked) => enableAIValidator(record, checked)}
              />
          }
        </div>

    },

  ]
  const tableData: Record[] = []
  ids.forEach(id => {
    tableData.push(data[id])
  });

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      extra={permissions === "ADMIN" && <Link to="/serviceowner_ai_validators/create"><Button type='primary'>Create AI Validator</Button></Link>}
      title="AI Validators"
    >
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey='id'
          columns={columns}
          pagination={{ total: total, pageSize: perPage, current: page, onChange: page => setPage(page) }}
          dataSource={tableData}
        />
      </SkeletonTable>
    </Card>
  )
}


const AIValidatorList = (props: ListProps) => {
  const { identity } = useGetIdentity();

  return (
    <Tabs
      defaultActiveKey="1"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <TabPane tab="AI Validators" key="1" >
        <ListBase {...props}>
          <AIValidatorListView />
        </ListBase>
      </TabPane>
      {
        identity?.is_superuser &&
        <TabPane tab="LLM Models" key="2" >
          <LLMModelsList resource={'llm_models'} basePath={'llm_models'}/>
        </TabPane>
      }
    </Tabs>
  )
}

export default AIValidatorList;
