import {Button, Form, Input, Modal} from "antd";
import React from "react";
import {useDataProvider, useNotify} from "react-admin";
import {setLocalStorageForUser} from "@src/components/Common/utils";


const TeamCreateModal = ({isModalOpen, setIsModalOpen} : {isModalOpen: boolean, setIsModalOpen: Function}) => {
    const [form] = Form.useForm();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const onSubmit = (values: any) => {
        dataProvider.create('teams', {data: values})
            .then((response: any) => {
                notify('New team is created')
                setLocalStorageForUser(response.data.active_membership)
                window.location.reload()
            })
            .catch((error: any) => {
                if (error.status == 400)
                    for (const [key, value] of Object.entries<any>(error.body))
                        form.setFields([{name: key, errors: value}])
            })
    }

    return (
        <Modal
            title="New Team"
            visible={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            footer={[
                <Button key="back" onClick={() => setIsModalOpen(false)}>Cancel</Button>,
                <Button key="submit" type="primary" onClick={form.submit}>Create</Button>
            ]}
            closable={false}
        >
            <Form className="row-col" form={form} onFinish={onSubmit}>
                <Form.Item label={"Name"} name={"name"} rules={[{ required: true, message: 'Required' }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default TeamCreateModal