import { Layout as AntLayout } from 'antd';
import React, { useEffect } from "react";
import {  LayoutComponent, ReduxState, registerResource, setSidebarVisibility, useLogout } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import AppHeader from './AppHeader';
import Footer from './Footer';
import Notification from './Notification';
import Sidebar from './Sidebar';
import { Helmet } from 'react-helmet';
import useGetCurrentResource from './useGetCurrentResource';
import { ErrorBoundary } from "react-error-boundary";
import { Error } from './Error';
import FaIcon from '../Icons/FaIcon';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import useGetGeneralSettings from '../../providers/useGetGeneralSettings';

const { Header, Content } = AntLayout;
const Layout: LayoutComponent = ({
    children
}) =>

{
    const dispatch = useDispatch();
    const currentResource = useGetCurrentResource();
    const logout = useLogout();
    const sidebarOpen = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    let { pathname } = useLocation();
    const generalSettings = useGetGeneralSettings();

    //Dynamic load of conditional Resources 
    useEffect(() => {

        const submissionBuilderSettings = generalSettings.find(setting => setting.name == 'SubmissionBuilder');
        submissionBuilderSettings?.config?.show_submission_builder == true  && dispatch(registerResource({
            name: 'submission_builder',
            options: { label: 'Submission Builder', 'viewMode': 'CONSUMER'},
            hasList: true,
            hasEdit: true,
            hasShow: true,
            hasCreate: true,
            icon: FaIcon(faMagic),
        }))

    }, []);

    return (
        <AntLayout className={`layout-dashboard`}>
            <Helmet>
                <title>{currentResource} - NetOrca</title>
            </Helmet>
            <Sidebar />
            <AntLayout>
                <ErrorBoundary
                    fallbackRender={({ error, resetErrorBoundary }) => (
                        <Error
                            error={error}
                            resetErrorBoundary={resetErrorBoundary}
                        />
                    )}
                >
                    <AppHeader toggleSidebar={()=>dispatch(setSidebarVisibility(!sidebarOpen))} logout={logout} pathName={pathname} />
                    <Content className="content-ant">
                        {children}
                    </Content>
                </ErrorBoundary>
                <Footer />
            </AntLayout>
            <Notification />
        </AntLayout>
    );
};

export default Layout;