import React, { useEffect } from 'react';
import { Button, Space, Typography, message } from 'antd';
import MonacoEditor from '@monaco-editor/react';
import { useServiceCreation } from '../contexts/ServiceCreationContext';
import { useDataProvider } from 'ra-core';
import { ExtendedDataProvider } from '@src/types';
import { defaultReadmeTemplate } from '../templates/readmeTemplate';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;

export const ReadmeEditor: React.FC = () => {
  const { state, setReadme } = useServiceCreation();
  const dataProvider: ExtendedDataProvider = useDataProvider();
  const history = useHistory();

  // Set default template when component mounts
  useEffect(() => {
    if (!state.readme) {
      setReadme(defaultReadmeTemplate);
    }
  }, []);

  const handleEditorChange = (value: string | undefined) => {
    setReadme(value || '');
  };

  const handleSubmit = async () => {
    try {

      const markdownBlob = new Blob([state.readme || ''], { type: 'text/markdown' });
      const formData = new FormData();
      formData.append('md_file', markdownBlob, 'readme.md');
  
      await dataProvider.uploadFile('services', {
        id: state.serviceId,
        data: formData,
        verb: 'docs/'
      });
      
  
      message.success('Documentation added successfully');
      // Redirect to the services list page after successful documentation upload
      history.push('/services');
    } catch (error) {
      message.error('Failed to add documentation: ' + (error as Error).message);
    }
  };

  return (
    <div>
      <Title level={4}>Service Documentation</Title>
      <div style={{ border: '1px solid #d9d9d9', borderRadius: '2px', marginBottom: '16px' }}>
        <MonacoEditor
          height="400px"
          language="markdown"
          theme="light"
          value={state.readme}
          onChange={handleEditorChange}
          options={{
            minimap: { enabled: false },
            wordWrap: 'on'
          }}
        />
      </div>
      <Space>
        <Button onClick={handleSubmit} type="primary">
          Save Documentation
        </Button>
      </Space>
    </div>
  );
}; 