import Form from '@rjsf/antd';
import { getDefaultRegistry } from '@rjsf/core';
import { DescriptionFieldProps, FieldProps, RJSFValidationError } from "@rjsf/utils";
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2020 from "ajv/dist/2020";
import { Typography } from 'antd';
import 'antd/es/alert/style/index.css';
import 'antd/es/input-number/style/index.css';
import React, { createRef, useMemo } from 'react';
import CustomArrayFieldTemplate from './CustomArrayFieldTemplate';
import CustomFieldTemplate from './CustomFieldTemplate';
import CustomObjectFieldTemplate from './CustomObjectFieldTemplate';
import { ExtraFieldsAlert, removeExtraFields, findExtraFields } from './ExtraFieldsHandler';
import TitleFieldTemplate from './TitleFieldTemplate';
import DescriptionFieldTemplate from './DescriptionFieldTemplate'
const { Title, Text } = Typography
const { ArrayField } = getDefaultRegistry().fields;
const validator = customizeValidator({ 
    AjvClass: Ajv2020
});

const CustomArrayField = function (props: FieldProps) {
    // TODO: This is temporary to support references. Will be changed in the next release
    const newSchema = { ...props.schema };
    if (!newSchema.items) {
        newSchema.items = { "type": "string" }
    }
    return (
        <ArrayField {...props} schema={newSchema} />
    )
};

function transformErrors(errors: RJSFValidationError[]) {
    return errors.map((error: RJSFValidationError) => {
        if (error.name === "pattern") {
            error.message = "Input doesn't match required pattern"
        }
        return error;
    });
}

const SchemaBasedForm = ({ style, recordId, schema, formData = null, onSave, onError, onSubmit }: { style?: React.CSSProperties, recordId: any, schema: any, formData?: any, onSave?: Function, onError?: Function, onSubmit?: Function }) => { 
    const formRef = createRef<any>();
    const uiSchema = {
        "ui:rootFieldId": recordId,
        "ui:classNames": "schema-form",
        "ui:submitButtonOptions": {
            "submitText": "",
        },
        "ui:order": ["name", "*"],
        title: {
            "classNames": "task-title"
        }
    };

    const originalFormData = useMemo(() => formData, []);
    const extraFields = useMemo(() => {
        if (!originalFormData) return [];
        return findExtraFields(originalFormData, schema.properties);
    }, [originalFormData, schema]);

    const handleChange = ({ formData }: { formData: any }) => {
        const cleanFormData = removeExtraFields(formData, extraFields);
        formRef.current?.submit()
        onSave && onSave(cleanFormData)
    }

    const handleError = (errors: any) => {
        onError && onError(errors)
    }

    const handleSubmit = ({ formData }: { formData: any }) => {
       const cleanFormData = removeExtraFields(formData, extraFields);
        onSubmit && onSubmit(cleanFormData);
    }

    const json = schema
    // causes validation caching issues when different schemas have same $id
    delete json['$id']
    const memoizedForm = useMemo(() => {
        return (
            <div style={style}>
                {extraFields.length > 0 && <ExtraFieldsAlert 
                    extraFields={extraFields} 
                    originalFormData={originalFormData} 
                />}
                <Form
                    id={recordId}
                    liveValidate
                    showErrorList={true}
                    ref={formRef}
                    fields={{ ArrayField: CustomArrayField }}
                    {...(formData && { formData })}
                    transformErrors={transformErrors}
                    templates={{
                        FieldTemplate: CustomFieldTemplate,
                        ObjectFieldTemplate: CustomObjectFieldTemplate,
                        ArrayFieldTemplate: CustomArrayFieldTemplate,
                        TitleFieldTemplate: TitleFieldTemplate,
                        DescriptionFieldTemplate: DescriptionFieldTemplate
                    }}
                    onError={handleError}
                    uiSchema={uiSchema} 
                    schema={json} 
                    validator={validator}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
            </div>
        )
    }, [formData, extraFields])

    return memoizedForm
}
export default SchemaBasedForm;