import { Button, Form, Input, Typography } from "antd";
import * as React from "react";
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import {setLocalStorageForUser} from "@src/components/Common/utils";
const { Title } = Typography

const CreateTeamForm = () => {
    const [form] = Form.useForm();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const onSubmit = (values: any) => {
        //@ts-ignore
        dataProvider.create('teams', { data: values })
            .then(response => {
                setLocalStorageForUser(response.data.active_membership)
                notify('Team created successfully');
                redirect('/teams')
            })
            .catch((error: any) => {
                if (error.status == 400) {
                    for (const [key, value] of Object.entries<any>(error.body)) {
                        form.setFields([
                            { name: key, errors: value }
                        ])
                    }
                }
            })
    }
    return (
        <Form
            form={form}
            name="create_team"
            className="row-col"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input Team Name' }]}
            >
                <Input placeholder="Team Name" />
            </Form.Item>
            <Form.Item>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="form-button">
                    Create
                </Button>
            </Form.Item>
        </Form>
    )
}

const CreateTeam = (props: any) => (
    <>
        <Title level={5} style={{ textAlign: 'center' }} className="mb-15">Create Team</Title>

        <CreateTeamForm />
    </>
)

export default CreateTeam;