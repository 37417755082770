import {Button, Card, Table, Tag, Typography, Tooltip} from 'antd';
import React, { useEffect, useState } from 'react';
import { ListBase, ListProps, Record as RaRecord, useListContext } from 'react-admin';
import {formatDateAndTime, formatDateAndTimeFromNow} from '../../helpers/utils';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import { getTagForChangeTypes, getTagForStates } from "../Common/Tags";
import GeneralFilter from "./GeneralFilter";
import { onExpandOnlyOneRow, tableOnChangeGenerator } from "../Common/tableUtils";
import { Link } from "react-router-dom";
import LazyExpandedRow from "../Common/LazyExpandedRow";
import DeclarationDiff from "./DeclarationDiff";
import ChangeInstanceLog from "@src/components/ChangeInstances/ChangeInstanceLog";


const { Text, Title } = Typography;


const ListView = () => {
  const { ids, data, loading, setSort, filterValues, setFilters, total, perPage, setPage, setPerPage, page } = useListContext();
  const [columns, setColumns] = useState<any[]>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const clearCommitIdFilter = () => {
    setFilters({ ...filterValues, 'commit_id': '' }, {})
  }

  useEffect(() => {
    const columnsData = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: { key: "id" },
        render: (text: any, record: any) => (
            <div style={{ display: 'grid', placeItems: 'stretch' }}>
              <Title level={5}>#{record.id}</Title>
              <Text type="secondary"><Tooltip title={formatDateAndTime(record.created)}><Text className='text-sm'>Created {formatDateAndTimeFromNow(record.created)}</Text></Tooltip></Text>
            </div>
        )
      },
      {
        title: 'Service Item',
        dataIndex: ['service_item', 'name'],
        key: 'service_item',
        render: (text: any, record: any) => (
            <div style={{ display: 'grid', placeItems: 'stretch' }}>
              <Link style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} to={`/service_items/${record.service_item.id}`}>
                  {record.service_item.name}
              </Link>
            </div>
        ),
      },
      {
        title: 'Application',
        dataIndex: ['application', 'name'],
        key: 'application',
        ellipsis: true,
      },
      {
        title: 'Service',
        dataIndex: ['service', 'name'],
        key: 'service',
        ellipsis: true,
      },
      {
        title: 'Service Owner',
        dataIndex: ['owner', 'name'],
        key: 'owner',
      },
      {
        title: 'Change Type',
        dataIndex: 'change_type',
        key: 'change_type',
        render: (text: any, record: any) => getTagForChangeTypes(record.change_type),
        sorter: { key: "change_type" },
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => getTagForStates(record.state),
        sorter: { key: "state" },
      },
      {
        title: 'Modified',
        key: 'modified',
        render: (text: any, record: any) => <Tooltip title={formatDateAndTime(record.modified)}><Text type='secondary'>{formatDateAndTimeFromNow(record.modified)}</Text></Tooltip>,
        sorter: true,
        responsive: ['xxl'],
      },
    ]
    setColumns(columnsData);
  }, [])

  const ConsumerChangeInstanceExpandedRow = ({ record }: { record: Record<string, any> }) => {
    return (
        <>
          <div style={{ marginLeft: '10px', marginRight: '10px', overflow: 'auto', width: '100%'}}>
            <ChangeInstanceLog change_instance={record} />
            <div style={{float: 'right', marginRight: '10px'}}>
              <Button type="primary">
                <Link to={`/submissions/${record.submission?.id}/`} >Submission page</Link>
              </Button>
            </div>
          </div>
          <DeclarationDiff change_instance={record} />
        </>
    )
  }



  const tableData: RaRecord[] = []
  data && ids.forEach(id => {
    tableData.push(data[id])
  });

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title={
        <div style={{paddingTop: '16px', position: 'absolute', top: 0, zIndex: 1}}>Change Instances<br />
          <span>
            {filterValues?.commit_id && <Tag color='#1890ff' closable onClose={clearCommitIdFilter}>Commit ID: {filterValues?.commit_id}</Tag>}
          </span>
        </div>
      }
      extra={
        <GeneralFilter
          filterValues={filterValues}
          setFilterValues={setFilters}
          applicationsResource={"applications"}
          serviceItemsResource={"service_items"}
          servicesResource={"consumer_services"}
          style={{ width: '350px' }}
        />
      }
    >
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey='id'
          columns={columns}
          expandable={{
            expandedRowKeys: expandedRowKeys,
            onExpand: onExpandOnlyOneRow(setExpandedRowKeys),
            expandedRowRender: (record) => <LazyExpandedRow lightRecord={record} resource={"change_instances"} ExpandedRowComponent={ConsumerChangeInstanceExpandedRow}/>,
            expandRowByClick: true
          }}
          dataSource={tableData}
          pagination={{
            pageSizeOptions: [25, 50, 100],
            onShowSizeChange: (_current, size) => setPerPage(size),
            showSizeChanger: true,
            total: total,
            pageSize: perPage,
            current: page,
          }}
          onChange={tableOnChangeGenerator(setSort, page, setPage)}
        />
      </SkeletonTable>
    </Card>
  )
}

const ChangeList = (props: ListProps) => (
  <ListBase
    sort={{ field: 'id', order: 'DESC' }}
    filter={{light: true}}
    perPage={25}
    filterDefaultValues={{ state: 'PENDING,APPROVED' }}
    {...props}
  >
    <ListView />
  </ListBase>
)

export default ChangeList;
