import { Table, Card, Tabs, Button } from 'antd';
import React, {useEffect, useState} from 'react';
import {ListBase, ListProps, useListContext, Record } from 'react-admin';
import {Link, useLocation} from "react-router-dom";
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import DependantServices from './DependantServices';
import {onExpandIcon, tableOnChangeGenerator} from "../Common/tableUtils";
import LazyExpandedRow from "../Common/LazyExpandedRow";
import ServiceRow from "./ServiceRow";
import { PlusOutlined } from '@ant-design/icons';
import { isVisibleCreateServiceButton } from './utils';

const { TabPane } = Tabs;

const ListView = () => {
  const { ids, data, loading, total, perPage, setPage, page, setPerPage, setSort } = useListContext();
  const location = useLocation();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  // TODO: there is a generic method for onExpand in another open MR. Once they are merged, we should used that method instead
  const onExpand = (expanded: boolean, record: any) => {
    if (expanded)
      setExpandedRowKeys([record.name])
    else
      setExpandedRowKeys([])
  }

  const toggleRowExpansion = (key: any) => {
    if(expandedRowKeys.includes(key))
      setExpandedRowKeys([])
    else
      setExpandedRowKeys([key])
  }

  useEffect(() => {
    setExpandedRowKeys([])
    toggleRowExpansion(location.hash.substring(1))
  }, [location]);

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Description',
      key: 'description',
      render: (text: any, record: any) => record?.schema?.schema?.description
    },
  ]

  const tableData: Record[] = []
  ids.forEach(id => {
    tableData.push(data[id])
  });
  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Services"
      extra={isVisibleCreateServiceButton() && <Link to="/services/create"><Button type='primary' icon={<PlusOutlined />}>Create Service</Button></Link>}
    >
      <Tabs style={{ paddingLeft: '24px' }} defaultActiveKey='1'>
        <TabPane tab="Team Services" key="1">
          <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
            <Table
              rowKey='name'
              columns={columns}
              pagination={{
                pageSizeOptions: [25, 50, 100],
                onShowSizeChange: (_current, size) => setPerPage(size),
                showSizeChanger: true,
                total: total,
                pageSize: perPage,
                current: page,
              }}
              dataSource={tableData}
              onChange={tableOnChangeGenerator(setSort, page, setPage)}
              expandable={{
                onExpand,
                expandRowByClick: true ,
                expandedRowRender: (record) => <LazyExpandedRow lightRecord={record} resource={"services"} ExpandedRowComponent={ServiceRow}/>,
                expandIcon: onExpandIcon(toggleRowExpansion, onExpand),
                expandedRowKeys: expandedRowKeys
              }}
            />
          </SkeletonTable>
        </TabPane>
        <TabPane tab="Dependant Services" key="2">
          <DependantServices />
        </TabPane>
      </Tabs>

    </Card>
  )
}

const ServiceList = (props: ListProps) => (
  <ListBase perPage={25} {...props}>
    <ListView />
  </ListBase>
)

export default ServiceList;
