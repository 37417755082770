import React from 'react';
import { Alert, Typography } from 'antd';
const { Text } = Typography;

interface ExtraFieldsAlertProps {
    extraFields: string[];
    originalFormData: any;
}

const formatValue = (value: any): string => {
    if (value === null) return 'null';
    if (value === undefined) return 'undefined';
    if (typeof value === 'object') {
        return JSON.stringify(value, null, 2);
    }
    return String(value);
};

const getValueFromPath = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc?.[part], obj);
};

export const ExtraFieldsAlert: React.FC<ExtraFieldsAlertProps> = ({ extraFields, originalFormData }) => {

    return (
        <Alert
            message="Warning: Extra Fields Detected"
            description={
                <div>
                    <Text>The following fields are no longer in the schema and will be ignored:</Text>
                    <ul style={{ 
                        listStyle: 'none', 
                        padding: '8px',
                        margin: '8px 0',
                        backgroundColor: '#fafafa',
                        borderRadius: '4px'
                    }}>
                        {extraFields.map(field => (
                            <li key={field} style={{ 
                                padding: '4px 0',
                                fontFamily: 'monospace'
                            }}>
                                <Text strong style={{ color: '#666' }}>{field}: </Text>
                                <span style={{ 
                                    fontFamily: 'monospace',
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-all'
                                }}>
                                    {formatValue(getValueFromPath(originalFormData, field))}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            }
            type="warning"
            style={{ marginBottom: 16 }}
            showIcon
        />
    );
};

export const removeExtraFields = (data: any, extraFields: string[]): any => {
    if (!data || typeof data !== 'object') return data;
    
    const cleanData = { ...data };
    extraFields.forEach(path => {
        const parts = path.split('.');
        let current = cleanData;
        
        // Navigate to parent of the field to remove
        for (let i = 0; i < parts.length - 1; i++) {
            if (current[parts[i]]) {
                current = current[parts[i]];
            }
        }
        
        // Remove the field
        const fieldToRemove = parts[parts.length - 1];
        delete current[fieldToRemove];
    });
    
    return cleanData;
};

export const findExtraFields = (data: any, schemaProperties: any, path: string = ''): string[] => {
    if (!data || typeof data !== 'object') return [];
    
    const extraFields: string[] = [];
    Object.keys(data).forEach(key => {
        const fullPath = path ? `${path}.${key}` : key;
        if (!schemaProperties || !(key in schemaProperties)) {
            extraFields.push(fullPath);
        } else if (typeof data[key] === 'object' && schemaProperties[key].properties) {
            extraFields.push(...findExtraFields(data[key], schemaProperties[key].properties, fullPath));
        }
    });
    return extraFields;
};