import { useDataProvider, useNotify, usePermissions, useQuery } from "react-admin";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Select, Space, Spin, Switch, Tabs } from "antd";
import ReactJson from "react-json-view";
import ManageAllowedTeams from "./ManageAllowedTeams";
import ManageDependantTeams from "./ManageDependantTeams";
import ManageServiceConfigs from "./ManageServiceConfigs";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { ExtendedDataProvider } from "@src/types";
import ServiceRowSchemaTab from './ServiceRowSchemaTab';

const { TabPane } = Tabs;
const { Option } = Select;

const ServiceRow = ({ record }: { record: Record<string, any> }) => {
    const { permissions } = usePermissions();
    const [form] = Form.useForm();
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const notify = useNotify();
    const [readmeData, setReadmeData] = useState<any>({});

    const { data: schemasVersions, loaded: versionsLoaded, error: versionsError } = useQuery({
        type: 'getAction',
        resource: 'service_schemas',
        payload: { verb: 'versions', filter: { service_id: record.id } }
    });

    useEffect(() => {
        record.has_md && fetchReadme(record.id)
    }, [record.id]);

    const fetchReadme = (service_id: number) => {
        dataProvider.getActionNonJson('browse_services', { id: service_id, verb: 'docs' })
            .then((response: any) => {
                setReadmeData((prev: any) => ({ ...prev, [service_id]: response.data.replace(/\\n/gi, '  \n') }))
            })
            .catch((error: any) => { console.log(error) })
    }

    const updateService = (values: any) => {
        dataProvider.update('services', { id: values.id, data: values, previousData: { id: values.id } })
            .then(response => notify('Service settings have been updated'))
            .catch(error => notify("Something went wrong", "warning"))
    }

    return (
        <>
            {
                record &&
                <Tabs defaultActiveKey="1" type='card'>

                    {record.has_md && (<TabPane style={{ padding: '20px' }} tab="Readme" key={record.id + "_readme"} >
                        <Card style={{ padding: '20px' }}>
                            {!readmeData[record.id] && <Spin style={{ 'width': '100%' }} />}
                            {readmeData[record.id] && <ReactMarkdown className='markdown-body' rehypePlugins={[rehypeRaw, remarkGfm]} children={String(readmeData[record.id])} />}
                        </Card>
                    </TabPane>)}
                    <TabPane tab="Schema" key="2">
                        <ServiceRowSchemaTab record={record} />
                    </TabPane>
                    <TabPane tab="Settings" key="3">
                        <Card style={{ padding: '20px' }}>
                            <Form onFinish={updateService} labelCol={{ span: 5 }} labelAlign={'left'} form={form}>
                                <Form.Item valuePropName='checked' label="Approval Required" name="approval_required">
                                    <Switch defaultChecked={record.approval_required} disabled={permissions !== "ADMIN"} />
                                </Form.Item >

                                <Form.Item valuePropName='checked' label="Enable Email Notifications" name="enabled_notifications">
                                    <Switch defaultChecked={record.enabled_notifications} disabled={permissions !== "ADMIN"} />
                                </Form.Item >

                                <Form.Item valuePropName='checked' label="Allow Manual Approval" name="allow_manual_approval">
                                    <Switch defaultChecked={record.allow_manual_approval} disabled={permissions !== "ADMIN"} />
                                </Form.Item >

                                <Form.Item valuePropName='checked' label="Allow Manual Completion" name="allow_manual_completion">
                                    <Switch defaultChecked={record.allow_manual_completion} disabled={permissions !== "ADMIN"} />
                                </Form.Item >

                                <Form.Item label="Minimum Schema Version" name="minimum_version">
                                    <Select defaultValue={record.minimum_version} style={{ width: '150px' }} placeholder="Select Version" disabled={permissions !== "ADMIN"}>
                                        <Option key={0} value={0}>Latest</Option>
                                        {
                                            schemasVersions &&
                                            Object.entries(schemasVersions).map(([key, value], index) => (
                                                // @ts-ignore
                                                <Option key={value.version} value={value.version}>{value.version}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    {
                                        permissions === "ADMIN" &&
                                        <Button type="primary" htmlType="submit">Save Changes</Button>
                                    }
                                </Form.Item>
                                <Form.Item hidden id="id" name="id" initialValue={record.id}>
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Card>
                    </TabPane>
                    <TabPane tab="Allowed Teams" key="4">
                        <ManageAllowedTeams record={record} />
                    </TabPane>
                    <TabPane tab="Dependant Teams" key="5">
                        <ManageDependantTeams record={record} />
                    </TabPane>
                    <TabPane tab="Service Configs" key="6">
                        <ManageServiceConfigs record={record} />
                    </TabPane>
                </Tabs>
            }
        </>
    )
}

export default ServiceRow;
