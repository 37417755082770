import React, { useState } from 'react';
import { Button, Card, Space, Typography, List, Modal, Form, Input, Checkbox, Radio, RadioChangeEvent } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useServiceCreation, ServiceField } from '../contexts/ServiceCreationContext';
import { useQuery } from 'react-admin';

const { Title } = Typography;
interface PropertyConfigModalProps {
  template: ServiceField;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (property: ServiceField) => void;
}

const PropertyConfigModal: React.FC<PropertyConfigModalProps> = ({ 
  template, 
  visible, 
  onCancel, 
  onSubmit 
}) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(values => {
      onSubmit({
        ...template,
        name: values.name,
        description: values.description,
        required: values.required,
      });
      form.resetFields();
    });
  };

  return (
    <Modal
      title="Configure Property"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ 
          name: '', 
          description: '',
          required: true 
        }}
      >
        <Form.Item
          name="name"
          label="Property Name"
          rules={[
            { required: true, message: 'Please input a property name' },
            { pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/, message: 'Name must start with a letter and contain only letters, numbers, and underscores' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item
          name="required"
          valuePropName="checked"
        >
          <Checkbox>Required field</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};


// Update the interface to match the actual data structure
interface TemplateData {
  id: number;
  name: string;
  description?: string;
  template_type: string;  // Changed from 'type' to 'template_type'
  schema?: any;
}

interface CustomFieldsSelectionProps {
  propertyTemplates: TemplateData[];
  selectedProperties: ServiceField[];
  onAddProperty: (fieldType: ServiceField) => void;
  onRemoveProperty: (name: string) => void;
  isLoading: boolean;
}

const CustomFieldsSelection: React.FC<CustomFieldsSelectionProps> = ({
  propertyTemplates,
  selectedProperties,
  onAddProperty,
  onRemoveProperty,
  isLoading
}) => {
  return (
    <>
      <div>
        <Title level={4}>Field Types</Title>
        {isLoading ? (
          <div>Loading property templates...</div>
        ) : (
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={propertyTemplates}
            renderItem={(fieldType: TemplateData) => (
              <List.Item>
                <Card
                  hoverable
                  size="small"
                  title={fieldType.name}
                  extra={
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        onAddProperty({
                          type: fieldType.id as any,
                          name: fieldType.name,
                          description: fieldType.description || '',
                          schema: fieldType.schema
                        });
                      }}
                    >
                      Add
                    </Button>
                  }
                >
                  {fieldType.description}
                </Card>
              </List.Item>
            )}
          />
        )}
      </div>
      <div>
        <Title level={4}>Selected Properties</Title>
        <List
          dataSource={selectedProperties}
          locale={{ emptyText: 'No fields selected yet' }}
          renderItem={(property: ServiceField) => (
            <List.Item
              actions={[
                <Button
                  key="delete"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => onRemoveProperty(property.name)}
                >
                  Remove
                </Button>
              ]}
            >
              <List.Item.Meta
                title={
                  <Space>
                    <span>{property.name}</span>
                    <Typography.Text type="secondary">
                      ({property?.schema?.type.replace(/_/g, ' ')})
                    </Typography.Text>
                    {property.required && 
                      <Typography.Text type="danger">required</Typography.Text>
                    }
                  </Space>
                }
                description={property.description}
              />
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

interface PredefinedTemplateSelectionProps {
  templates: TemplateData[];
  onTemplateSelect: (template: TemplateData) => void;
  isLoading: boolean;
}

const PredefinedTemplateSelection: React.FC<PredefinedTemplateSelectionProps> = ({
  templates,
  onTemplateSelect,
  isLoading
}) => {
  return (
    <div>
      <Title level={4}>Available Templates</Title>
      {isLoading ? (
        <div>Loading templates...</div>
      ) : (
        <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={templates}
          renderItem={(template: TemplateData) => (
            <List.Item>
              <Card
                hoverable
                title={template.name}
                onClick={() => onTemplateSelect(template)}
              >
                {template.schema?.description}
              </Card>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export const PropertySelection: React.FC = () => {
  const { state, addProperty, removeProperty, prevStep, nextStep, setJsonSchema } = useServiceCreation();
  const [selectedFieldType, setSelectedFieldType] = useState<ServiceField | null>(null);
  const [mode, setMode] = useState<'custom' | 'template'>('custom');
  
  const { data: templateResponse, loading: templatesLoading } = useQuery({
    type: 'getList',
    resource: 'service_templates',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    },
  });

  const serviceTemplatesData = templatesLoading ? [] : (templateResponse || []);
  const fullTemplates = serviceTemplatesData.filter((template: TemplateData) => template.template_type === 'FULL');
  const propertyTemplates = serviceTemplatesData.filter((template: TemplateData) => template.template_type === 'PROPERTY');

  const handleModeChange = (e: RadioChangeEvent) => {
    const newMode = e.target.value;
    setMode(newMode);
    // Clear properties when switching to template mode
    if (newMode === 'template' && state.selectedProperties.length > 0) {
      // Clear all properties from context
      state.selectedProperties.forEach(property => {
        removeProperty(property.name);
      });
    }
  };

  const handleTemplateSelect = (template: TemplateData) => {
    setJsonSchema(JSON.stringify(template.schema, null, 2));
    nextStep();
  };

  const handleAddProperty = (fieldType: ServiceField) => {
    setSelectedFieldType(fieldType);
  };

  const handleModalSubmit = (property: ServiceField) => {
    addProperty(property);
    setSelectedFieldType(null);
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Card>
          <Title level={3}>Define Service Schema</Title>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Radio.Group 
              onChange={handleModeChange} 
              value={mode}
              style={{ 
                width: '100%',
                display: 'flex',
                gap: '16px'
              }}
            >
              <Card
                hoverable
                style={{
                  width: '50%',
                  borderColor: mode === 'custom' ? '#1890ff' : '#d9d9d9',
                  backgroundColor: mode === 'custom' ? '#e6f7ff' : 'white'
                }}
                onClick={() => {
                  // Let the Radio.Group handle the mode change through handleModeChange
                  const event = { target: { value: 'custom' } } as RadioChangeEvent;
                  handleModeChange(event);
                }}
              >
                <Radio value="custom">
                  <Space direction="vertical">
                    <Title level={4}>Build Custom Schema</Title>
                    <Typography.Text type="secondary">
                      Create a tailored service schema by selecting and configuring individual properties.
                    </Typography.Text>
                  </Space>
                </Radio>
              </Card>

              <Card
                hoverable
                style={{
                  width: '50%',
                  borderColor: mode === 'template' ? '#1890ff' : '#d9d9d9',
                  backgroundColor: mode === 'template' ? '#e6f7ff' : 'white'
                }}
                onClick={() => {
                  // Let the Radio.Group handle the mode change through handleModeChange
                  const event = { target: { value: 'template' } } as RadioChangeEvent;
                  handleModeChange(event);
                }}
              >
                <Radio value="template">
                  <Space direction="vertical">
                    <Title level={4}>Use Predefined Template</Title>
                    <Typography.Text type="secondary">
                      Select from ready-made schema templates with pre-configured properties.
                    </Typography.Text>
                  </Space>
                </Radio>
              </Card>
            </Radio.Group>

            <div style={{ marginTop: '24px' }}>
              {mode === 'custom' ? (
                <Card>
                  <CustomFieldsSelection
                    propertyTemplates={propertyTemplates}
                    selectedProperties={state.selectedProperties}
                    onAddProperty={handleAddProperty}
                    onRemoveProperty={removeProperty}
                    isLoading={templatesLoading}
                  />
                </Card>
              ) : (
                <Card>
                  <PredefinedTemplateSelection
                    templates={fullTemplates}
                    onTemplateSelect={handleTemplateSelect}
                    isLoading={templatesLoading}
                  />
                </Card>
              )}
            </div>

            <Space>
              <Button onClick={prevStep}>
                Previous
              </Button>
              <Button 
                type="primary" 
                onClick={nextStep}
                disabled={mode === 'custom' && state.selectedProperties.length === 0}
              >
                Next
              </Button>
            </Space>
          </Space>
        </Card>

        {selectedFieldType && (
          <PropertyConfigModal
            template={selectedFieldType}
            visible={true}
            onCancel={() => setSelectedFieldType(null)}
            onSubmit={handleModalSubmit}
          />
        )}
      </Space>
    </div>
  );
}; 