import React from 'react';
import { ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';
import WelcomeScreen from '../Welcome';
import BaseDashboard from "./Dashboard";
import {getActiveMembership} from "@src/components/Common/utils";

const Dashboard = () => {
  const welcomeScreenShown = useSelector((state: ReduxState) => state.ui.welcomeScreenShown);
  const viewMode = localStorage.getItem('pov_preference') || useSelector((state: ReduxState) => state.ui.viewMode);
  const activeMembership = getActiveMembership()

  return (
    <>
      {
        viewMode === 'CONSUMER'?
            <BaseDashboard
                pov={viewMode}
                statisticsResource={"consumer_statistics"}
                applicationsResource={"applications"}
                changeInstancesResource={"change_instances"}
                servicesResource={"consumer_services"}
                chargesResource={"consumer_charges"}
            />
            :
            <BaseDashboard
                pov={viewMode}
                statisticsResource={"serviceowner_statistics"}
                teamsResource={"consumer_teams"}
                applicationsResource={"owner_applications"}
                changeInstancesResource={"owner_change_instances"}
                servicesResource={"services"}
                chargesResource={"serviceowner_charges"}
            />
      }
      {!activeMembership?.active  &&  !welcomeScreenShown && <WelcomeScreen />}
    </>
  )
}

export default Dashboard;